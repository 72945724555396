import React, { useState, useRef } from 'react'
import _ from 'lodash'
import {
  Formik, Form, Field, ErrorMessage,
} from 'formik'
import Select from 'react-select'
import { Button } from '../../../../components'
import customStyles from './customStyleSelect'
import quotationSchema from './validation-squema'
import './form-quotation-styles.scss'

const FormQuotation = ({
  t, data, onSubmit, isUpdating,
}) => {
  const [plans, setPlans] = useState([])
  const formRef = useRef()
  const howOptions = [
    { label: t('quotation.form.how.google'), value: 'google' },
    { label: t('quotation.form.how.facebook'), value: 'facebook' },
    { label: t('quotation.form.how.instagram'), value: 'instagram' },
    { label: t('quotation.form.how.family'), value: 'family' },
    { label: t('quotation.form.how.friends'), value: 'friends' },
    { label: t('quotation.form.how.events'), value: 'events' },
    { label: t('quotation.form.how.billboards'), value: 'billboards' },
    { label: t('quotation.form.how.viber'), value: 'viber' },
    { label: t('quotation.form.how.others'), value: 'others' },
  ]

  const days = [
    { label: t('quotation.form.days.monday'), value: 'monday' },
    { label: t('quotation.form.days.tuesday'), value: 'tuesday' },
    { label: t('quotation.form.days.wednesday'), value: 'wednesday' },
    { label: t('quotation.form.days.thursday'), value: 'thursday' },
    { label: t('quotation.form.days.friday'), value: 'friday' },
    { label: t('quotation.form.days.saturday'), value: 'saturday' },
    { label: t('quotation.form.days.sunday'), value: 'sunday' },
  ]

  const purposeOptions = _.reduce(_.get(data, 'quotation_purpose'), (result, value, key) => {
    const option = { label: value, value: key }
    result.push(option) 
    return result
  }, [])

  const timeOptions = [
    { label: '8am - 10am', value: '8am-10am' },
    { label: '10am - 12pm', value: '10am-12pm' },
    { label: '12pm - 2pm', value: '12pm-2pm' },
    { label: '2pm - 4pm', value: '2pm-4pm' },
    { label: '4pm - 6pm', value: '4pm-6pm' },
    { label: '6pm - 8pm', value: '6pm-8pm' },
  ]

  const RadioButton = ({
    field: {
      name, value, onChange, onBlur,
    },
    id,
    label,
    className,
    ...props
  }) => (
    <div className="radioButton__container">
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        className="radioButton"
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )

  const SelectField = ({
    options, field, form, placeholder,
  }) => (
    <Select
      placeholder={placeholder}
      options={options}
      name={field.name}
      value={
        options ? options.find((option) => option.value === field.value) : ''
      }
      onChange={(option) => form.setFieldValue(field.name, option.label)}
      onBlur={field.onBlur}
      styles={customStyles}
    />
  )

  const onFilterPlans = (e) => {
    if (e.value === 'others') {
      setPlans([])
      formRef.current.setFieldValue('purpose', e.label)
      formRef.current.setFieldValue('purposeProduct', e.label)
    } else {
      formRef.current.setFieldValue('purpose', e.label)
      const options = _.head(_.filter(data.product_list, { slug: e.value }))
      setPlans(options.product)
    }
  }

  const onFilterDays = (e) => {
    formRef.current.setFieldValue('day', e.label)
  }

  return (
    <div className="formQuotation__container">
      <Formik
        innerRef={formRef}
        onSubmit={(values) => {
          let newValues
          if (values.contactDay !== 'Specific day') {
            newValues = {
              ...values,
              day: '',
            }
            onSubmit(newValues)
          } else {
            onSubmit(values)
          }
        }
        }
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          contactNumber: '',
          location: '',
          purpose: '',
          purposeProduct: '',
          marketingConsent: false,
          message: '',
          age: '',
          occupation: '',
          source: '',
          communication: '',
          contactDay: '',
          day: '',
          contactTime: '',
          accept: false,
          accept2: false,
        }}
        validationSchema={quotationSchema}
      >
        {(formik) => (
          <Form>
            <div className="formQuotation__form__title ">
              <p>{t('quotation.form.personal')}</p>
            </div>
            <div className="row">
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="location">
                  {t('quotation.form.firstName')}
                </label>
                <Field
                  type="text"
                  name="firstName"
                  className="input"
                  placeholder=""
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="firstName"
                  component="div"
                />
              </div>
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="lastName">
                  {t('quotation.form.lastName')}
                </label>
                <Field
                  placeholder=""
                  className="input"
                  type="text"
                  name="lastName"
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="lastName"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="age">
                  {t('quotation.form.age')}
                </label>
                <Field
                  type="text"
                  name="age"
                  className="input"
                  placeholder=""
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="age"
                  component="div"
                />
              </div>
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="occupation">
                  {t('quotation.form.occupation')}
                </label>
                <Field
                  placeholder=""
                  className="input"
                  type="text"
                  name="occupation"
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="occupation"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="location">
                  {t('quotation.form.location')}
                </label>
                <Field
                  type="text"
                  name="location"
                  className="input"
                  placeholder={t('quotation.form.locationPlaceholder')}
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="location"
                  component="div"
                />
              </div>
            </div>

            <div className="formQuotation__form__title up">
              <p>{t('quotation.form.quotation')}</p>
            </div>
            <div className="row">
              <div className="col-sm-6 col-12 formQuotation__form__group">
                <label className="">{t('quotation.form.purpose.title')}</label>
                <Select
                  placeholder={' - Select -'}
                  options={purposeOptions}
                  // name={field.name}
                  // value={options ? options.find((option) => option.value === field.value) : ''}
                  onChange={(option) => onFilterPlans(option)}
                  styles={customStyles}
                />
              </div>
              <div className="col-sm-6 col-12 formQuotation__form__group">
                {!_.isEmpty(plans) && (
                  <React.Fragment>
                    <label className="" htmlFor="purpose">
                      {t('quotation.form.plan')}
                    </label>
                    <Field
                      name={'purposeProduct'}
                      placeholder={' - Select -'}
                      component={SelectField}
                      options={plans}
                      type="text"
                    />
                  </React.Fragment>
                )}
              </div>
              <ErrorMessage
                className="formQuotation__error"
                name="purpose"
                component="div"
              />
            </div>

            <div className="formQuotation__form__title up">
              <p>{t('quotation.form.availability')}</p>
            </div>
            <div className="row">
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="contactNumber">
                  {t('quotation.form.contact')}
                </label>
                <Field
                  type="text"
                  name="contactNumber"
                  className="input"
                  placeholder=""
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="contactNumber"
                  component="div"
                />
              </div>
              <div className="col-sm-6 formQuotation__form__group">
                <label className="" htmlFor="email">
                  {t('quotation.form.email')}
                </label>
                <Field
                  placeholder=""
                  className="input"
                  type="text"
                  name="email"
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="email"
                  component="div"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 formQuotation__form__group">
                <label className="" htmlFor="reach">
                  {t('quotation.form.reach.title')}
                </label>
                <div role="group" aria-labelledby="reach-radio-group">
                  <Field
                    component={RadioButton}
                    name="communication"
                    id="Phone"
                    label={t('quotation.form.reach.phone')}
                  />
                  <Field
                    component={RadioButton}
                    name="communication"
                    id="Email"
                    label={t('quotation.form.reach.email')}
                  />
                  <Field
                    component={RadioButton}
                    name="communication"
                    id="Both Phone & E-mail"
                    label={t('quotation.form.reach.both')}
                  />

                </div>

                <ErrorMessage
                  className="formQuotation__error"
                  name="communication"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 formQuotation__form__group">
                <label className="" htmlFor="contactDay">
                  {t('quotation.form.when.title')}
                </label>
                <div className='row'>
                  <div className="col-8 pt-2">
                    <div role="group" aria-labelledby="when-radio-group">
                      <Field
                        component={RadioButton}
                        name="contactDay"
                        id="Weekdays"
                        label={t('quotation.form.when.weekdays')}
                      />
                      <Field
                        component={RadioButton}
                        name="contactDay"
                        id="Weekends"
                        label={t('quotation.form.when.weekends')}
                      />
                      <Field
                        component={RadioButton}
                        name="contactDay"
                        id="Specific day"
                        label={t('quotation.form.when.specific')}
                      />
                    </div>
                  </div>
                  {
                    formik.values.contactDay === 'Specific day' && (
                      <div className="col-4 formQuotation__form__group">
                        <Select
                          placeholder={' - Select -'}
                          options={days}
                          onChange={(option) => onFilterDays(option)}
                          styles={customStyles}
                        />
                      </div>

                    )
                  }
                </div>
                <ErrorMessage
                  className="formQuotation__error"
                  name="contactDay"
                  component="div"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 formQuotation__form__group">
                <label className="" htmlFor="when">
                  {t('quotation.form.what.title')}
                </label>
                <Field
                  name={'contactTime'}
                  placeholder={t('quotation.form.what.whatPlaceholder')}
                  component={SelectField}
                  options={timeOptions}
                  type="contactTime"
                />
                {/* {errors && errors.communication} */}
                <ErrorMessage
                  className="formQuotation__error"
                  name="contactTime"
                  component="div"
                />
              </div>
            </div>

            <div className="formQuotation__form__title up">
              <p>{t('quotation.form.others')}</p>
            </div>
            <div className="row">
              <div className="col-sm-12 formQuotation__form__group">
                <label className="" htmlFor="when">
                  {t('quotation.form.how.title')}
                </label>
                <Field
                  placeholder={t('quotation.form.how.placeholder')}
                  name={'source'}
                  component={SelectField}
                  options={howOptions}
                  type="source"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 formQuotation__form__group">
                <label className="" htmlFor="when">
                  {t('quotation.form.free')}
                </label>
                <Field
                  component="textarea"
                  rows={4}
                  name="message"
                  className="textarea"
                />
                <ErrorMessage
                  className="formQuotation__error"
                  name="message"
                  component="div"
                />
              </div>
            </div>

            <div className="formQuotation__box__checkbox">
              <label className="formQuotation__checkbox">
                <div
                  className="rich-text"
                  dangerouslySetInnerHTML={{
                    __html: _.get(data, 'consent_text'),
                  }}
                />
                {/* <Field type="checkbox" name="marketingConsent" /> */}
                <Field type="checkbox" name="accept" />
                <span className="formQuotation__checkbox__span"></span>
                <ErrorMessage
                  className="formQuotation__error"
                  name="accept"
                  component="div"
                />
              </label>
            </div>
            <div className="formQuotation__box__checkbox">
              <label className="formQuotation__checkbox">
                <div
                  className="rich-text"
                  dangerouslySetInnerHTML={{
                    __html: _.get(data, 'terms_and_condition'),
                  }}
                />

                {/* <Field type="checkbox" name="accept" /> */}
                {/* <Field type="checkbox" name="marketingConsent" />                 */}
                <Field type="checkbox" name="accept2" />
                <span className="formQuotation__checkbox__span"></span>
                <ErrorMessage
                  className="formQuotation__error"
                  name="accept2"
                  component="div"
                />
              </label>
            </div>
            <div className="formQuotation__button__box">
              <Button
                disable={isUpdating}
                big
                type={'submit'}
                text={t('quotation.form.button')}
                disabled={isUpdating}
              />
            </div>
            {/* <pre>{JSON.stringify(formik, null, 4)}</pre> */}
          </Form>

        )

        }
      </Formik>
    </div>
  )
}

export default FormQuotation
