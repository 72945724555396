import React from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner, Success, Seo } from '../../components'
import FormQuotation from './components/form/form-quotation'
import './quotation.scss'

const QuotationView = ({
  loading, data, onSubmit, success, isUpdating,
}) => {
  const { t } = useTranslation()

  if (loading) {
    return <Spinner loading={loading} />
  }

  return (
    <>
      <Seo title="Pacific Cross: Get a Quote" />
      <div className="container-fluid">
        {success ? (
          <Success t={t} />
        ) : (
          <div className="quotation__container">
            <div className="row">
              <div className="col-12 col-sm-6 quotation__content">
                <div className="quotation__header">
                  <span className="page-tag">{t('quotation.content.tag')}</span>
                  <h1 className="page-title">{t('quotation.content.title')}</h1>
                  <div
                    className="rich-text"
                    dangerouslySetInnerHTML={{
                      __html: t('quotation.content.description'),
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6 quotation__form">
                <FormQuotation
                  isUpdating={isUpdating}
                  data={data}
                  onSubmit={onSubmit}
                  t={t}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default QuotationView
